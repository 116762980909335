import type { V } from "@component-utils/types"

export const findSelectOption = <T extends string | number>(value: NoInfer<T> | undefined | null, field: keyof V.Select.Item<T>, options: V.Select.Option<T>[]): V.Select.Item<T> | undefined => {
  if (typeof value === 'undefined' || value === null) return undefined
  else return options.reduce<V.Select.Item<T> | undefined>((memo, option) => {
    if (memo) return memo
    else if ('options' in option) return findSelectOption(value, field, option.options)
    else {
      if (option[field] === value) return option
    }
  }, undefined)
}
